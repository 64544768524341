<!--
 * @Author: dongjia
 * @Date: 2021-08-17 10:45:35
 * @LastEditTime: 2021-12-20 14:14:43
 * @LastEditors: aleaner
 * @Description: 黑名单管理列表
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\black-member\list.vue
 * 怎么可能会有bug！！！
-->
<!--
 * @Author: dongjia
 * @Date: 2021-08-16 15:10:50
 * @LastEditTime: 2021-08-17 10:24:55
 * @LastEditors: aleaner
 * @Description: 评论管理列表页
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\comment-admin\list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <list-filter
      :filterForm="filterForm"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button size="small" @click="handleCancelblack"> 移出 </el-button>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                size="small"
                v-if="scope.row.is_forever_black === 0"
                @click="blackRow(scope.row, 1)"
                type="text"
              >
                永久拉黑
              </el-button>
              <el-button
                size="small"
                v-if="scope.row.is_forever_black === 1"
                @click="blackRow(scope.row, 0)"
                type="text"
              >
                取消永久
              </el-button>
              <el-button
                size="small"
                @click="cancelblackRow(scope.row)"
                type="text"
              >
                移出
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[filterForm.tab]">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <span v-if="th.prop === 'posts'">{{
                `${scope.row.posts_count}/${scope.row.complaints_count}`
              }}</span>
              <span v-else-if="th.prop === 'comments'">{{
                `${scope.row.comments_count}/${scope.row.comment_complaints_count}`
              }}</span>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
  </div>
</template>

<script>
import listFilter from "../../components/BlackMembersFilter";
import Pagination from "@/base/components/Default/Pagination";
import { blackList, foreverBlack, cancelBlack } from "../../api/black-member";
export default {
  data() {
    return {
      tabArr: [
        { label: "全局拉黑", name: "global" },
        { label: "组内拉黑", name: "group" },
      ],
      loading: false, //加载
      thead: {
        global: [
          { label: "用户名称", prop: "nickname", minWidth: 110 },
          { label: "发布帖子总数/被举报数", prop: "posts", minWidth: 180 },
          { label: "发布评论总数/被举报数", prop: "comments", minWidth: 180 },
          {
            label: "拉黑时间",
            prop: "black_time",
            minWidth: 160,
            sortable: true,
          },
          {
            label: "自动移出",
            prop: "move_text",
            minWidth: 110,
            sortable: true,
          },
        ],
        group: [
          { label: "用户名称", prop: "nickname", minWidth: 110 },
          { label: "被拉黑小组", prop: "group_name", minWidth: 120 },
          { label: "组内帖子总数/被举报数", prop: "posts", minWidth: 180 },
          { label: "组内评论总数/被举报数", prop: "comments", minWidth: 180 },
          {
            label: "拉黑时间",
            prop: "black_time",
            minWidth: 160,
            sortable: true,
          },
          {
            label: "自动移出",
            prop: "move_text",
            minWidth: 110,
            sortable: true,
          },
        ],
      },
      //筛选对象
      filterForm: {
        nickname: "", // 创建者名称
        start_time: -1, //更新开始时间
        end_time: -1, //更新结束时间
        tab: "global", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      // 筛选缓存
      filters: {
        global: null,
        group: null,
      },
      handleLabelWidth: {
        checked: 210,
        checking: 260,
        uncheck: 210,
        deleted: 100,
      },
      pageData: {
        page_size: 15,
        tab: "checking",
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      PostConfig: {},
      noCheckData: {},
      replyData: {},
      // openAddPost: false, // 开启设置弹窗
      openReplyDialog: false,
      openNoCheckedDialog: false,
      changeRowType: "change",
    };
  },
  methods: {
    // 获取品牌列表
    getBlackList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      blackList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.selectedMembers = data.user_ids;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 永久拉黑、取消永久黑
    blackRow(row, is_forever_black) {
      if (is_forever_black) {
        this.$msgbox
          .confirm("永久拉黑后，用户需手动移出黑名单，请谨慎操作", "提示", {
            type: "warning",
          })
          .then((res) => {
            this.handleForeverBlack(row.id, is_forever_black);
          })
          .catch((err) => {});
      } else {
        this.handleForeverBlack(row.id, is_forever_black);
      }
    },
    // 永久拉黑/取消永久操作
    handleForeverBlack(id, is_forever_black) {
      this.loading = true;
      foreverBlack({ id, tab: this.filterForm.tab, is_forever_black })
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.refreshList();
        });
    },
    // 批量取消拉黑
    handleCancelblack() {
      if (this.selectArr.length) {
        this.loading = true;
        cancelBlack({ id: this.selectArr, tab: this.filterForm.tab })
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.refreshList();
          });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 取消拉黑
    cancelblackRow(row) {
      this.loading = true;
      cancelBlack({ id: row.id, tab: this.filterForm.tab })
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.refreshList();
        });
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          nickname: "", // 创建者名称
          start_time: -1, //更新开始时间
          end_time: -1, //更新结束时间
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getBlackList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getBlackList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getBlackList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getBlackList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getBlackList(pageData);
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    this.getBlackList(this.filterForm); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.black-member {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.black-member {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
